import React from 'react'
import Tree from 'react-hyper-tree'

import { BOULDER } from '@/constants/styles/defaultTheme'
import TreeNodeContainer from '../TreeNode'


import TreeContainer from './styles'
import { ROOT } from '@/constants/objectTypes'

const lineStyles = {
  stroke: BOULDER,
  strokeWidth: 1,
  strokeDasharray: '1 1',
}

const TreeWrapped = ({
  id,
  tree: {
    required,
    handlers,
  },
  isMonitoring,
  selectedNode,
  isSchedule,
  setInViewButton,
  refInView,
  onSelectNode,
  checkTree,
  onCheckNode
}) => {

  const renderNode = (params) => {
    const {node} = params
    const isRoot = node.data.type === ROOT || node.data.isRoot

    return (
    <TreeNodeContainer
      {...params}
      isMonitoring={isMonitoring}
      isSchedule={isSchedule}
      selectedNode={selectedNode}
      id={id}
      setInViewButton={setInViewButton}
      refInView={isRoot ? refInView : null}
      onSelectNode={onSelectNode}
      checkTree={checkTree}
      onCheckNode={onCheckNode}
    />
  )
  }
  return (
    <TreeContainer key={`${id}-container`}>
      <Tree
        key={`${id}-tree`}
        renderNode={renderNode}
        disableTransitions
        verticalLineOffset={9}
        verticalLineTopOffset={-9}
        horizontalLineStyles={lineStyles}
        verticalLineStyles={lineStyles}
        gapMode="padding"
        depthGap={20}
        classes={{ selectedNodeWrapper: 'selected-node' }}
        {...required}
        {...handlers}
      />
    </TreeContainer>
  )
}

export default React.memo(TreeWrapped)
